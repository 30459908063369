<template>
  <main>
    <section class="login-form">
      <div class="login-form-inner">
        <img :src="thdLogo" class="thd-logo" width="70" alt="thd logo" />
        <h2 class="header-2">Login</h2>
        <p>
          The Home Depot uses your network username and password to login to
          Onboarding Journey. Continue to login to Onboarding Journey through
          your network.
        </p>

        <SimpleButton
          class="button"
          label="Continue"
          full-width
          @click="login"
        />
      </div>
    </section>
    <SloganLetsBuild />
  </main>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import SimpleInput from "@/components/ui/SimpleInput";
import thdLogo from "../assets/images/THD_Logo 1.png";
import SimpleButton from "@/components/ui/SimpleButton";
import SloganLetsBuild from "@/components/SloganLetsBuild";
import AuthAPI from "@/api/AuthAPI";
import UserAPI from "@/api/UserAPI";
import { userNames } from "@/store/modules/user";
import { useCookies } from "vue3-cookies";
const router = useRouter();
const store = useStore();

const ldap = ref("");
const password = ref("");
const login = async () => {
  // const login = await AuthAPI.login();
  try {
    window.location.href = `${process.env.VUE_APP_URL}/auth/init`;
    // const user = await UserAPI.getMyUser();
    // store.commit(userNames.setUser, user);
    // await router.push('/');
  } catch (e) {
    console.error(e);
  }
};
</script>

<style scoped>
p {
  margin: 40px 0 40px 0;
}
main {
  display: flex;
  min-height: 100vh;
}

.login-form {
  display: flex;
  width: 48%;
  justify-content: center;
}

.login-form-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 320px;
  height: 100%;
}
.button {
  width: 192px;
  padding: 0;
}
.thd-logo {
  margin-top: 20px;
  margin-bottom: 70px;
  height: 64px;
  width: 214px;
}

.role {
  text-transform: uppercase;
  color: #ff8217;
  margin: 0 0 10px 0;
}

.header-2 {
  margin: 0;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  font-size: 48px;
  color: black;
}
.forgot-password {
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  text-align: right;
  text-decoration: none;
  color: #ff8217;
}

.divider {
  width: 100%;
  height: 2px;
  margin: 50px 0 30px;
  border: 0;
  background: #e5e5e5;
}

.sign-up-text {
  margin-top: 20px;
  text-align: center;
}

.sign-up-text-inner {
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  text-decoration: none;
  color: #ff8217;
}
</style>
